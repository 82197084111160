import "lazysizes";
import "@script/swiper.jquery-3.4.2.js";
import "@src/scripts/iconfont.js";

import "@src/styles/style.css";
import "lightbox2/src/css/lightbox.css";

import { main, toggle_anchor } from "@src/scripts/script.js";

$(main);
window.toggle_anchor = toggle_anchor;